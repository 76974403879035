import ProductionPartApiService from '@/services/api/ProductionPartApiService';
import ProductionPart from '@/domain/ProductionPart';
import PlannedPart from '@/domain/PlannedPart';
import { useNotification } from '@/composable/useNotifications';
import PlannedPartDTO from '@/dtos/PlannedPartDTO';
import { GetResultSetWithCountDTO } from '@/dtos/GetResultSetWithCountDTO';
import PlannedPartSearchFilter from '@/dtos/filters/PlannedPartSearchFilter';
import { getTitleCaseTranslation } from '@/services/TranslationService';

export default class ProductionPartService {
    private partApiService: ProductionPartApiService;

    private notification = useNotification();

    constructor() {
        this.partApiService = new ProductionPartApiService();
    }

    public async addProductionPart(part: ProductionPart): Promise<ProductionPart | null> {
        const response = await this.partApiService.addProductionPart(part);
        if (!response.success) {
            return null;
        }
        this.notification.showSuccess(getTitleCaseTranslation('core.common.addedProductionPart'));

        return new ProductionPart({
            ...part,
            id: response.data,
        });
    }

    public async updateProductionPart(part: ProductionPart): Promise<ProductionPart | null> {
        const response = await this.partApiService.updateProductionPart(part);
        if (!response.success) {
            return null;
        }
        this.notification.showSuccess(getTitleCaseTranslation('core.common.updatedProductionPart'));

        return new ProductionPart({
            ...part,
        });
    }

    public async addPlannedPart(part: PlannedPartDTO): Promise<PlannedPartDTO | null> {
        const response = await this.partApiService.addPlannedPart(part);
        if (!response.success) {
            return null;
        }
        this.notification.showSuccess(getTitleCaseTranslation('core.common.addedPlannedPart'));

        return new PlannedPartDTO({
            ...part,
            id: response.data,
        });
    }

    public async updatePlannedPart(part: PlannedPartDTO): Promise<PlannedPartDTO | null> {
        const response = await this.partApiService.updatePlannedPart(part);
        if (!response.success) {
            return null;
        }
        this.notification.showSuccess(getTitleCaseTranslation('core.common.updatedPlannedPart'));

        return new PlannedPartDTO({
            ...part,
        });
    }

    public async getAllProductionParts(): Promise<{ parts: Array<ProductionPart> }> {
        const response = await this.partApiService.getAllProductionParts();

        return { parts: response.data };
    }

    public async getAllPlannedParts(): Promise<{ parts: Array<PlannedPartDTO>, success: boolean }> {
        const response = await this.partApiService.getAllPlannedParts();
        return { parts: response.data, success: response.success };
    }

    public async getPlannedPartById(id: number): Promise<{ part: PlannedPart; success: boolean; message: string }> {
        const { data, success, message } = await this.partApiService.getPlannedPartById(id);
        return { part: data, success, message };
    }

    public async getFilteredPlannedParts(filter: PlannedPartSearchFilter): Promise<GetResultSetWithCountDTO<PlannedPartDTO>> {
        const dto = filter.toDTO;
        const { success, data } = await this.partApiService.getFilteredPlannedParts(dto);
        return success ? data : { resultSet: [], totalMatchedRecords: 0 };
    }

    public async getFilteredPlannedPartsByFromLocation(filter: PlannedPartSearchFilter, fromLocationId: Number): Promise<GetResultSetWithCountDTO<PlannedPartDTO>> {
        const dto = filter.toDTO;
        const { success, data } = await this.partApiService.getFilteredPlannedPartsByFromLocation(dto, fromLocationId);
        return success ? data : { resultSet: [], totalMatchedRecords: 0 };
    }
}
