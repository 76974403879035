import axios, { AxiosResponse } from 'axios';
import { API_SERVICE_URL } from '@/config/env';
import ProductionPart from '@/domain/ProductionPart';
import { DataAccessResponse } from '@/types/api/DataAccessResponse';
import { DataAccessResponseClass, DataListAccessResponseClass } from '@/services/api/dataAccessResponse';
import PlannedPartDTO from '@/dtos/PlannedPartDTO';
import PlannedPart from '@/domain/PlannedPart';
import { GetResultSetWithCountDTO } from '@/dtos/GetResultSetWithCountDTO';
import { PlannedPartSearchFilterDTO } from '@/dtos/filters/PlannedPartSearchFilter';

export default class ProductionPartApiService {
    public async addProductionPart(part: ProductionPart): Promise<DataAccessResponse<number>> {
        const response: AxiosResponse<DataAccessResponse<number>> = await axios({
            method: 'POST',
            url: `${API_SERVICE_URL}/Parts/production/add`,
            data: part,
        });

        return new DataAccessResponseClass<number>(response).response;
    }

    public async updateProductionPart(part: ProductionPart): Promise<DataAccessResponse<boolean>> {
        const response: AxiosResponse<DataAccessResponse<boolean>> = await axios({
            method: 'PUT',
            url: `${API_SERVICE_URL}/Parts/production/update`,
            data: part,
        });

        return new DataAccessResponseClass<boolean>(response).response;
    }

    public async addPlannedPart(part: PlannedPartDTO): Promise<DataAccessResponse<number>> {
        const response: AxiosResponse<DataAccessResponse<number>> = await axios({
            method: 'POST',
            url: `${API_SERVICE_URL}/Parts/planned/add`,
            data: part,
        });

        return new DataAccessResponseClass<number>(response).response;
    }

    public async updatePlannedPart(part: PlannedPartDTO): Promise<DataAccessResponse<boolean>> {
        const response: AxiosResponse<DataAccessResponse<boolean>> = await axios({
            method: 'PUT',
            url: `${API_SERVICE_URL}/Parts/planned/update`,
            data: part,
        });

        return new DataAccessResponseClass<boolean>(response).response;
    }

    public async getAllProductionParts(): Promise<DataAccessResponse<Array<ProductionPart>>> {
        const response: AxiosResponse<DataAccessResponse<Array<ProductionPart>>> = await axios({
            method: 'GET',
            url: `${API_SERVICE_URL}/Parts/production/getAll`,
        });
        return new DataListAccessResponseClass<ProductionPart>(response, ProductionPart).response;
    }

    public async getAllPlannedParts(): Promise<DataAccessResponse<Array<PlannedPartDTO>>> {
        const response: AxiosResponse<DataAccessResponse<Array<PlannedPartDTO>>> = await axios({
            method: 'GET',
            url: `${API_SERVICE_URL}/Parts/planned/getAll`,
        });

        return new DataListAccessResponseClass<PlannedPartDTO>(response, PlannedPartDTO).response;
    }

    public async getPlannedPartById(id: number): Promise<DataAccessResponse<PlannedPart>> {
        const response: AxiosResponse<DataAccessResponse<PlannedPart>> = await axios({
            method: 'GET',
            url: `${API_SERVICE_URL}/Parts/planned/getById/${id}`,
        });

        return new DataAccessResponseClass<PlannedPart>(response, PlannedPart).response;
    }

    public async getFilteredPlannedParts(dto: PlannedPartSearchFilterDTO): Promise<DataAccessResponse<GetResultSetWithCountDTO<PlannedPartDTO>>> {
        const response: AxiosResponse<DataAccessResponse<GetResultSetWithCountDTO<PlannedPartDTO>>> = await axios({
            method: 'POST',
            url: `${API_SERVICE_URL}/Parts/planned/filter`,
            data: dto,
        });
        response.data.data.resultSet = response.data.data.resultSet.map((t) => new PlannedPartDTO(t));

        return new DataAccessResponseClass<GetResultSetWithCountDTO<PlannedPartDTO>>(response).response;
    }

    public async getFilteredPlannedPartsByFromLocation(dto: PlannedPartSearchFilterDTO, fromLocationId: Number): Promise<DataAccessResponse<GetResultSetWithCountDTO<PlannedPartDTO>>> {
        const response: AxiosResponse<DataAccessResponse<GetResultSetWithCountDTO<PlannedPartDTO>>> = await axios({
            method: 'POST',
            url: `${API_SERVICE_URL}/Parts/planned/filter/${fromLocationId}`,
            data: dto,
        });
        response.data.data.resultSet = response.data.data.resultSet.map((t) => new PlannedPartDTO(t));

        return new DataAccessResponseClass<GetResultSetWithCountDTO<PlannedPartDTO>>(response).response;
    }
}
